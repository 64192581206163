// main
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xz";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import UserDetailsModule from "../content/consumer/user-details";
import TransferListModule from "../content/consumer/transfers-list";

import { UserDetails } from "../services/data-consumers-realm";

export default function ConsumerView () {
  
  const metadata = {
    name: 'Consumer',
    banner: {
      link: 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=600'
    }
  }

  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const [data, setData] = useState()

  useEffect( () => {
    if (id){
      const fetchData = async() => {

        setLoading(true);
        
        var datx = { user: id }
        var result = (await UserDetails({data: datx, srvc: '******'}))
        // console.log(result)

        setData(result.data)

        setLoading(false);

      }
      fetchData()
    } else {}
  },[id]);


  return(

  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'xtra', visible: true, data: 
      <>
        <WebbHeader data={{home: '/', name: metadata.name, link: '/'}}/>
      </>
      }}

      media = {{ size: 'xtra', visible: false, link: metadata.banner.link, data: <>
        <WebbDividerMedium />
        
        <div className="container text-color-wite">
          <p className="text-lead text-bold">Consumer</p>
        </div>
        
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerSmall/>
      </> }}

      content = {{ size: 'xtra', data: 
      <>

        <div className="container" 
          // style={{
          //   backgroundImage:`url(${metadata.banner.link})`, 
          //   backgroundRepeat:'no-repeat', 
          //   backgroundSize:'cover',
          //   backgroundPosition: 'center center',
          //   height:'100vh'
          // }}
        >
        
          <WebbDividerMedium />
          <div className={loading ? '' : 'd-none'}>
            Please wait...
          </div>

          <div className={!loading && !data ? '' : 'd-none'}>
            Not Found
          </div>

          <div className={!loading && data ? '' : 'd-none'}>
            <UserDetailsModule />
          </div>
          

          <WebbDividerMedium />
          <div className={!loading && data ? '' : 'd-none'}>
            <h2 className='text-lead m-0'>Transactions</h2>
            <p className='text-normal m-0'></p>
          </div>

          <WebbDividerMedium /> 
          <div className={!loading && data ? '' : 'd-none'}>
            <TransferListModule />
          </div>
          
          
          <WebbDividerMedium /> 
          <WebbDividerMedium />
          <WebbDividerMedium /> 
          <WebbDividerMedium /> 
          <WebbDividerMedium />
          <WebbDividerMedium />         
        </div>

      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <>
        <div className="">
        
        </div>
      </>
      }}
    
    
    ></ContentFormat>


  </>
  )
}