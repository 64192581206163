import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from '../context/authcontext'

// views - main
import Main from '../views.xz/main'
import MainAPI from '../views.xz/api'
import MainContact from '../views.xz/contact'

// views - users
import UserView from '../views.users/users-view'
import CreatorView from '../views.users/creator-view'
import ConsumerView from '../views.users/consumer-view'
import CheckerView from '../views.users/checker-view'

// views - transfers
import TransferList from '../views.transfers/transfers-list'
import TransfersDetails from '../views.transfers/transfers-details'

// views - assets
import AssetsList from '../views.assets/assets-list'

// views - users


// views - auth
import AuthMailCode from '../views.auth/auth-mail-code'
import AuthSessionX from '../views.auth/auth-session-x'


const routes = [
  
  { route: '/', content: <Main />, auth: false },
  { route: '/api', content: <MainAPI />, auth: false },
  { route: '/contact', content: <MainContact />, auth: false },

  // users
  { route: '/user/:id', content: <UserView />, auth: false },
  { route: '/creator/:id', content: <CreatorView />, auth: false },
  { route: '/consumer/:id', content: <ConsumerView />, auth: false },
  { route: '/checker/:id', content: <CheckerView />, auth: false },
  

  // transfers
  { route: '/assets', content: <AssetsList />, auth: false },

  // transfers
  { route: '/transfers', content: <TransferList />, auth: false },
  { route: '/tx/:id', content: <TransfersDetails />, auth: false },
  
  // user
  { route: '/ux/:id', content: <TransfersDetails />, auth: false }, //

  // assets
  { route: '/ax/:id', content: <TransfersDetails />, auth: false },
 

  // auth
  { route: '/auth', content: <AuthMailCode />, auth: false },
  { route: '/auth/x', content: <AuthSessionX />, auth: true },

]

export default function RouteX () {
  const { user } = useAuth()
  // // console.log (user)

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route
            key={i}
            path={item.route}
            element={!user ? <Navigate to='/' replace /> : item.content}
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  )
}
