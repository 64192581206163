// transfers
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";


import { DataListTransfers } from "../../services/data-creators-realm";


const listMethod = [
  {form: 'asset', sort: 'transfer', icon: 'bx bx-badge-check'},
  {form: 'data', sort: 'transfer', icon: 'bx bx-data'},
]

const listStatus = [
  {code: 0, color: 'text-color-tint'},
  {code: 1, color: 'text-color-warning'},
  {code: 2, color: 'text-color-warning'},
  {code: 3, color: 'text-color-warning'},
  {code: 4, color: 'text-color-danger'},
  {code: 5, color: 'text-color-danger'},
  {code: 6, color: 'text-color-success'},
  {code: 7, color: 'text-color-danger'},
  {code: 8, color: 'text-color-danger'},
  {code: 9, color: 'text-color-next'}
]


export default function TransferListModule(props) {

  const asset = true
  const {id} = useParams()
  const [loading, setLoading] = useState(false);

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [data, setData] = useState([]);
  const [stream, setStream] = useState([]);
  const [refresh, setRefresh] = useState(false)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)

  const [memo, setMemo] = useState('')
  const [search, setSearch] = useState(props.search || '')

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        setLoading(true);
        
        var datx = { user: id, index: index, items: items, filters:{"search": search} }
        var result = (await DataListTransfers({data: datx, srvc: '******'}))
        console.log(result)
        
        setData (result.data.list)
        setTotal(result.data.count)

        setMemo(`${((index-1) * items +1)} - ${index * items < result.data.count ? index * items : result.data.count} of ${result.data.count}`)
        setLoading(false);

      }
      fetchData()
    } else {}
  },[search, index, items]);


  const NextIndex = async() =>{
    if(data.length < items) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }

  // if (loading){ return ( <> Please Wait... </> ) }
  // if (!data || data.length === 0) { return ( <> No Data... </> ) }

  return (
  <>
    <div className="">
      <p>Transfers: {memo}</p>
    </div>

    {/* web */}
    <div className="back-color-wite border rounded shadow-sm">
    {data && data.map((item, i) => (
      <div className="" key={i}>
        <div className="d-flex p-3 border-bottom hilite cursor">
          <div className="" style={{width: '60%'}}>
            <p className="text-uppercase m-0" style={{}}>
              
              <span className="me-2">
                <i className={listMethod.find(x => x.form == item.feat.form && x.sort == item.feat.sort).icon}></i>
              </span>
              
              <span className={listStatus.find(x => x.code ==( item?.stat || 0)).color}>
                <i className='bx bxs-circle text-mini'></i>
              </span>
              <span className="ms-2">{item?.trxn || '******'}</span>
            </p>
            
          </div>

          <div className="d-none d-md-block" style={{width: '33%'}}>
            <p className=" m-0">
              <span>{((new Date(parseInt(item?.crts || '0'))).toISOString()).split('T').join(' - ')}</span>
            </p>
          </div>          
        
          <div className="ms-auto text-end">
            <p className="text-small text-color-tone m-0">{'View'}</p>
            <p className="text-small m-0">{' '}</p>
          </div>
        </div>
        
      </div>
      ))}

    </div>

    {/* mobile */}
    <div className="back-color-wite border rounded shadow-sm d-none">
    {data && data.map((item, i) => (
      <div className="" key={i}>
        <div className="d-flex p-3 border-bottom hilite">

          <div className="ms-2" style={{width: '69%'}}>
            <p className="text-truncate m-0" style={{}}>
              <span className={listStatus.find(x => x.code ==( item?.stat || 0)).color}>
                <i className='bx bxs-circle text-mini'></i>
              </span>
              <span className="ms-1">{item?.trxn || '******'}</span>
            </p>
            
          </div>

          <div className="ms-auto text-end">
            <p className="m-0">{'View'}</p>
            <p className="text-small m-0">{' '}</p>
          </div>

        </div>
      </div>
    ))}        
    </div>



    {/* navs */}
    <WebbDividerSmall />
    <div className={data.length < items ? '' : ''}>
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{memo}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>   

  </>
  )
}