// transfers
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { TransferDetails } from "../../services/data-transferx-realm";


const listMethod = [
  {form: 'asset', sort: 'transfer', icon: 'bx bx-badge-check'},
  {form: 'data', sort: 'transfer', icon: 'bx bx-data'},
]

const listStatus = [
  {code: 0, color: 'text-color-tint', memo: 'Created'},
  {code: 1, color: 'text-color-warning', memo: 'In Process'},
  {code: 2, color: 'text-color-warning', memo: 'Scheduled'},
  {code: 3, color: 'text-color-warning', memo: 'Created'},
  {code: 4, color: 'text-color-danger', memo: 'Created'},
  {code: 5, color: 'text-color-danger', memo: 'Created'},
  {code: 6, color: 'text-color-success', memo: 'Closed (Submitted)'},
  {code: 7, color: 'text-color-danger', memo: 'Declined (User)'},
  {code: 8, color: 'text-color-danger', memo: 'Revoked (User)'},
  {code: 9, color: 'text-color-next', memo: 'Partial (User)'}
]

export default function TransferDetailsModule(props) {

  const asset = true
  const {id} = useParams()
  const [loading, setLoading] = useState(false);

  const [item, setItem] = useState();
  const [data, setData] = useState();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        setLoading(true);
        
        var datx = { item: id }
        // var result = (await UserDetails({data: datx, srvc: '******'}))
        console.log(props.data)
        setItem(props?.data)

        if (props?.data?.status == 6) {
          const result = await TransferDetails({data: {item: id}, srvc: '******'})
          console.log (result)
          if (result.data) setData(result.data)
        }

        setLoading(false);

      }
      fetchData()
    } else {}
  },[id, props]);

  if (loading) return <></>

  return (
  <>
    <div className="">
      <p className='text-lead text-truncate'>
        <span className={listStatus.find(x => x.code == (item?.status || 0)).color}>
          <i className='bx bxs-circle text-mini'></i>
        </span>
        <span className="ms-2">ID: {id}</span>

      </p>
      <p className='m-0'>
        <span className="me-1">Status: </span>
        <span>{listStatus.find(x => x.code ==( item?.status || 0)).memo}</span>
      </p>

      <p className=''>
        <span className="me-1">Date: </span>
        <span>{item?.created}</span>
      </p>
    </div>

    <WebbDividerMedium />
    <div className="">
      <p className='text-bold text-color-tone m-0'>{item?.feat?.form == 'data' ? 'Consumer' : 'Creator'}</p>
      <p className='text-normal text-bold m-0'>{item?.credit?.name}</p>
      <p className='m-0'>{item?.credit?.item}</p>
    </div>

    <WebbDividerSmall />
    <div className="">
      <p className='text-bold text-color-tone m-0'>{'User'}</p>
      <p className='text-normal text-bold m-0'>{item?.debit?.name}</p>
      <p className='m-0'>{item?.debit?.item}</p>
    </div>

    <WebbDividerSmall />
    <div className="">
      <p className='text-bold text-color-tone m-0'>{'Data'}</p>
    </div>

    <div className={!loading && !data ? '' : 'd-none'}>
      <p className='m-0'>{'Not Available'}</p>
    </div>

    <div className={!loading && data ? '' : 'd-none'}>
    {data && data?.vars?.map((item, i) => (
      <div className="mb-3" key={i}>

        {/* intro */}
        <div className="">
          <p className='text-bold m-0'>
            <span className='me-1'>{i+1}.</span>
            <span className=''>{item?.name}</span>
          </p>
          <p className='m-0'>
            <span className=''>{item?.memo}</span>
          </p>

        </div>

        {/* data */}
        <div className="">
          <p className='text-color-next m-0'>
            <span className='text-normal'>{item?.data}</span>
          </p>
        </div>

        <WebbDividerSmall />
      </div>
    ))}
    </div>

  </>
  )
}