// transfers
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { UserDetails } from "../../services/data-users-realm";

export default function UserDetailsModule(props) {

  const asset = true
  const {id} = useParams()
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        setLoading(true);
        
        var datx = { user: id }
        var result = (await UserDetails({data: datx, srvc: '******'}))
        console.log(result)

        setData(result.data)

        setLoading(false);

      }
      fetchData()
    } else {}
  },[id]);


  return (
  <>
    <div className="">
      <p className='text-lead text-bold m-0'>Name: {data?.name || '******'}</p>
      <p className='text-color-tone'>ID: {id}</p>
    </div>


  </>
  )
}