// transfers
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import DataTable from "react-data-table-component";

// const mode = require('../../data/code-transfers-mode.json').data;

import { DataListAssets } from "../../services/data-assets-realm";
import { DataStreamAssets } from "../../services/data-assets-stream";

const listStatus = [
  {code: 0, color: 'text-color-tint'},
  {code: 1, color: 'text-color-warning'},
  {code: 2, color: 'text-color-warning'},
  {code: 3, color: 'text-color-warning'},
  {code: 4, color: 'text-color-danger'},
  {code: 5, color: 'text-color-danger'},
  {code: 6, color: 'text-color-success'},
  {code: 7, color: 'text-color-danger'},
  {code: 8, color: 'text-color-danger'},
  {code: 9, color: 'text-color-next'}
]

const columns = [
  { 
    name: <i className='bx bxs-circle'></i>, selector: 'stat', width:'5%',
    cell: row => 
    <div className={`text-small`}>
      <span className={listStatus.find(x => x.code ==( row?.stat || 0)).color}>
        <i className='bx bxs-circle'></i>
      </span>
    </div>
  },
   { 
    name: 'Transaction', selector: 'item', width:'40%',
    cell: row => 
    <div className={`text-small`}>
      <span className="text-sm">{row.trxn.toUpperCase()}</span>
    </div>
  },
  { name: 'Method', selector: 'feat', width:'20%',
    cell: row => 
    <div className={`rounded p-1 px-2 text-small back-color-lite`}>
      <span className="text-dark m-0">{row.feat.form}.{row.feat.sort}</span>
    </div>
  },
  { 
    name: 'Date (UTC)', selector: 'created', sortable: true, width:'25%',
    cell: row => 
    <div className={`text-small`}>
      <span className="">{(new Date(parseInt(row?.crts || '0'))).toISOString()}</span>
      <span>{' '}</span>
      {/* <span className="">{(new Date(row?.crts)).toISOString().substring(11,16)}</span> */}
    </div>
  },
  { name: 'View', selector: 'item', width:'10%',
    cell: row => 
    <div className={`text-small`}>
      <span className="m-0 cursor">View</span>
    </div>
  }
];

const customStyles = {
  rows: {
    style: {
      fontSize: '1rem'
    }
  },
  headCells: {
    style: {
      backgroundColor: '#FFF',
      fontWeight: '',
      color: 'blue'
    },
  },
  cells: {
    style: {
    },
  },
};

export default function AssetListModule(props) {

  const asset = true
  const [loading, setLoading] = useState(false);

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [data, setData] = useState([]);
  const [stream, setStream] = useState([]);
  const [refresh, setRefresh] = useState(false)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)

  const [memo, setMemo] = useState('')
  const [search, setSearch] = useState(props.search || '')

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        setLoading(true);
        
        var datx = { index: index, items: items, filters:{"search": search} }
        var result = (await DataListAssets({data: datx, srvc: '******'}))
        console.log(result)
        
        setData (result.data.list)
        setTotal(result.data.count)

        setMemo(`${((index-1) * items +1)} - ${index * items < result.data.count ? index * items : result.data.count} of ${result.data.count}`)
        setLoading(false);

      }
      fetchData()
    } else {}
  },[search, index, items]);

  // stream 
  useEffect( () => {
    const fetchData = async() => {
      const datastream = await DataStreamAssets()
      console.log(datastream)

      const datx = {
        cred: datastream.cred, 
        debt: datastream.debt,
        feat: datastream.feat,
        trxn: datastream.trxn, 
        crts: datastream.crts,
        item: datastream.item
      }

      const indx = stream.findIndex(x => x.item === datastream.item)
      if (indx < 0) {
        setStream([...[datx]])
      }
      setRefresh(!refresh)
    }
    fetchData()
  },[refresh]);

  // stream 
  useEffect( () => {
      const fetchData = async() => {
        const newlist = [...stream, ...data]
        newlist.sort((a, b) => b.crts - a.crts)
        newlist.pop()
        setData(newlist)
        setTotal(count+1)
      }
      fetchData()
    
  },[stream]);

  const NextIndex = async() =>{
    if(data.length < items) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }

  // if (loading){ return ( <> Please Wait... </> ) }
  // if (!data || data.length === 0) { return ( <> No Data... </> ) }

  return (
  <>
    <div className="">
      <p>Assets: {memo}</p>
    </div>

    <div className="">

      <div className="border rounded shadow-sm">
        <DataTable
          noHeader
          highlightOnHover
          title=""
          columns={columns}
          data={data}
          customStyles={customStyles}
        />
      </div>

    </div>

    {/* navs */}
    <WebbDividerSmall />
    <div className={data.length < items ? '' : ''}>
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{memo}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>   

  </>
  )
}