// init
import * as Realm from 'realm-web';
const base = ''

const app = new Realm.App({ id:"bharat-data-zkzkv"});

const scrt = "XDTi8iW3SrHkJOYra9bETh5h0RxBFw3WUvD30Ni1SpmiC2XY52aeBND8M77BT3yj" //process.env.REACT_APP_WEBB_MNDB_APIK
const dbsx = "2465ad21731dc42b6b4a6176" //process.env.REACT_APP_WEBB_MNDB_DBSE
const dbcx = "33cc469f6aba4363" //process.env.REACT_APP_WEBB_MNDB_ASSX

export const DataStreamAssets = async() => {

  if (!app.currentUser) {
    await app.logIn(Realm.Credentials.apiKey(scrt));
  }
  
  const mndb = app.currentUser.mongoClient("mongodb-atlas");
  const dbse = mndb.db(dbsx).collection(dbcx);

  for await (const docx of dbse.watch()) {
    switch (docx.operationType) {
      case "insert": {
        const { documentKey, fullDocument } = docx;
        // console.log(`new: ${documentKey.toString()}`, fullDocument);
        return fullDocument
        break;
      }

      case "update": {
        const { documentKey, fullDocument } = docx;
        // console.log(`new: ${documentKey.toString()}`, fullDocument);
        return fullDocument
        break;
      }      
        
    }
  }
}

// 33cc488f6aba4363-b256-0356cbd9beac
// 63300fdc-ae2b-4d79-9e38-19859668f163
// 6e19a5f0-c5be-4a4d-a0e6-36e902786e08
// 69941506-84fe-462a-a536-43a2a24bda7c
// 9d9dca10-4bb4-471a-8c99-d186f4ee833d
// 2465ad21731dc42b6b4a6176

// 99496d2cb7a7424c