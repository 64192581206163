// navs
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";


const listNavs = require('../../data.static/navs-header-xz.json').data

export default function WebbHeaderNavs(props) {

  const location = useLocation();  
  const linx = location.pathname.split('/')[1]
  const form = location.pathname.split('/')[1]

  const data = listNavs.filter(item => item.actv );

  console.log(linx)

  return (
    <>
    {/* header-large */}
    <div className="">
      {data && data.map((item, i) => (
        
        <Link to={`/${item.link}`} key={i}>
          {item.link === linx 
            ? <span className='p-2 text-color-dark me-1'>{item.name}</span>
            : <span className='p-2 text-color-next hidark rounded me-1'>{item.name}</span>
          }
        </Link>
      
      ))}

    </div>
  
    </>
  )
}