// init
import axios from 'axios'
const base = 'https://ap-south-1.aws.data.mongodb-api.com/app/bharat-data-zkzkv/endpoint'


export const DataListTransfers = async (item) => {

  const basx = base + '/transfers/list/data'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }

}



export const DataTransfersDetails = async (item) => {

  const basx = base + '/transfers/details'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }

}